<template>
  <div class="page-management" v-loading="loading">
    <div class="management-header">
      <div class="management-header-text">报告管理</div>
    </div>
    <el-table
      :data="tempList"
      class="management-table"
      :header-cell-style="{
        color: '#aaa',
        background: '#F5F7FA',
      }"
    >
      <my-empty slot="empty" :empty-text="$t('h.song.emptyText')"></my-empty>
      <el-table-column
        property="report_id"
        label='report_id'
        min-width="20%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        property="track_name"
        label='歌曲名'
        min-width="20%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        property="company"
        label='公司'
        min-width="20%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label='报告状态'
        min-width="20%"
        show-overflow-tooltip
      >
      <div slot-scope="scope">
        {{handelStatus(scope.row.status)}}
      </div>
      </el-table-column>
      <el-table-column
        property="management_name"
        label='操作'
        min-width="20%"
        show-overflow-tooltip
      >
        <div slot-scope="scope">
          <a @click="copyLink(scope.row)" class="check-btn"> 复制链接 </a>
          <a @click="openLink(scope.row)" class="check-btn ml20"> 打开链接 </a>
        </div>
      </el-table-column>
    </el-table>

    <my-pagination
      :page-size="pageSize"
      :current-page="currentPage"
      :total="bondsAllList.length"
      @change-page="changePage"
    ></my-pagination>
  </div>
</template>
<script>
export default {
  name: "Task",

  data() {
    return {
      currentPage: 1,
      pageSize: 9,
      bondsAllList: [],
      tempList: [],
      loading: "",
    };
  },

  mounted() {
    this.getdata();
  },

  methods: {
    changePage(page) {
      this.currentPage = +page;
      this.currentChangePage(this.bondsAllList, this.currentPage);
    },
    //分页方法
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tempList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tempList.push(list[from]);
        }
      }
    },
    async getdata() {
      this.loading = true;
      const result = await this.$store.dispatch("getReportList", {
      });
      if (result.success) {
        this.bondsAllList = result.reports;
        this.currentChangePage(this.bondsAllList, this.currentPage);
        this.loading = false;
      } else {
        this.$message({
          message: result.msg,
          type: "error"
        })
      }
    },
    handelStatus(status){
      if(status){
        return "已完善"
      }else{
        return "未完善"
      }
    },
    openLink (row) {
      let host = window.location.host
      if (host === 'pdm.woa.com') {
        host = 'pdm.tencentmusic.com'
      }
      const url = window.location.protocol+"//"+ host +""+window.location.pathname+"#/report?report_id="+row.report_id
      window.open(url)
    },

    copyLink(row){
      let host = window.location.host
      if (host === 'pdm.woa.com') {
        host = 'pdm.tencentmusic.com'
      }
      const url = window.location.protocol+"//"+ host +""+window.location.pathname+"#/report?report_id="+row.report_id
      console.log(url)
      let input = document.createElement('textarea')
      input.value = url
      input.setAttribute('readonly', '')
      document.body.appendChild(input)
      input.select()
      if (document.execCommand('copy')) {
          document.execCommand('copy')
          this.$message({
              message: '复制成功',
              type: "success",
              offset: 100
          })
      }
      document.body.removeChild(input)
    }
  },
};
</script>
<style lang="stylus" scoped>
.page-management {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.management-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.management-header-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  color: #1a1a1a;
}

.management-table {
  flex: 1;
}

.check-btn {
  cursor: pointer;
  color: #ff8c36;
}
.check-btn:hover {
  font-size: 15px;
}
</style>